<template>
  <div class="chartPart">
    <div class="container">
      <div class="title">销售统计</div>
      <div class="statistics-detail">
        <div class="order-number">
          <div>分销商数量</div>
          <div class="order-statistics">{{ saleData.totalUser || '0' }}</div>
        </div>
        <div class="order-number">
          <div>订单总量</div>
          <div class="order-statistics">{{ saleData.orderCount || '0' }}</div>
        </div>
        <div class="order-number">
          <div>销售总额(￥)</div>
          <div class="order-statistics">{{ saleData.productAmount || '0' }}</div>
        </div>
      </div>
      <p class="tips">
        统计结果更新时间 :
        <span class="tips-time">{{ updateTime }}</span>
      </p>
    </div>
    <div class="container">
      <div class="title">品类销售额统计(￥)</div>
      <div class="statistics-detail">
        <myChart :data="chartData"></myChart>
      </div>
    </div>
  </div>
</template>

<script>
import myChart from './MyChart.vue'
import { getCtegoryData } from '@/api/statistics.js'
import { parseTime } from '@/utils/index.js'
import { debounce } from 'lodash'

export default {
  components: {
    myChart
  },
  props: {
    saleData: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    },
    activeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chartData: [],
      updateTime: ''
    }
  },
  computed: {},
  watch: {
    query: {
      handler(newVal) {
        if (this.activeName === 'user') {
          this.getChartData(newVal)
        }
      },
      deep: true
    }
  },
  methods: {
    getChartData: debounce(
      async function (newQuery = {}) {
        const { detail } = await getCtegoryData(newQuery)
        const chartData = detail.map(({ categoryName, productAmount }) => {
          return {
            name: categoryName,
            value: productAmount || 0
          }
        })
        this.chartData = chartData
      }
    ),
    getCurrentTime() {
      const date = new Date()
      return parseTime(date)
    }
  },
  created() {
    this.getChartData()
    this.updateTime = this.getCurrentTime()
  }
}
</script>

<style scoped lang="scss">
.chartPart {
  margin: 15px 0;
  color: #495060;
  display: flex;
  .container:nth-of-type(1) {
    margin-right: 20px;
  }
  .container {
    position: relative;
    flex: 1;
    .tips {
      position: absolute;
      bottom: 10px;
      right: 20px;
      font-size: 14px;
      color: #999;
      .tips-time {
        margin-left: 5px;
      }
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .statistics-detail {
      display: flex;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      font-size: 14px;
      height: 240px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      box-shadow: 0px 0px 5px 2px rgba($color: #9c9c9c, $alpha: 0.2);
      border-radius: 4px;
      .order-number {
        .order-statistics {
          font-size: 28px;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}
</style>
