<template>
  <div class="order-statistics">
    <div class="info-box">
      <div class="info-item">
        <p class="item-font">订单总量</p>
        <p class="item-num">{{ data.orderCount || '0' }}</p>
      </div>
      <div class="info-item">
        <p class="item-font">销售产品数量</p>
        <p class="item-num">{{ data.productCount || '0' }}</p>
      </div>
      <div class="info-item">
        <p class="item-font">销售总额(￥)</p>
        <p class="item-num">{{ data.productAmount || '0' }}</p>
      </div>
    </div>
    <p class="tips-font">统计结果更新时间: {{ updateTime }}</p>
  </div>
</template>

<script>
import { parseTime } from '@/utils/index.js'
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      updateTime: ''
    }
  },
  computed: {},
  methods: {
    getCurrentTime() {
      const date = new Date()
      return parseTime(date)
    }
  },
  created() {
    this.updateTime = this.getCurrentTime()
    console.log('this.updateTime', this.updateTime)
  }
}
</script>

<style scoped lang="scss">
.order-statistics {
  border: 1px solid #ccc;
  height: 250px;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  .info-box {
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .info-item {
      text-align: center;
      .item-font {
        font-size: 14px;
      }
      .item-num {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
      }
    }
  }
  .tips-font {
    height: 18px;
    line-height: 18px;
    font-size: 14px;
    color: rgb(146, 146, 146);
  }
}
</style>
