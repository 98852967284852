export const styleTableCols = [
  {
    label: '原型分类',
    prop: 'categoryName'
  },
  {
    label: '款式名称',
    prop: 'styleName',
    slotName: 'styleCodeSlot'
  },
  {
    label: '销售数量',
    prop: 'productCount'
  }
]
export const SKUTableCols = [
  {
    label: '排名',
    width: '50px',
    slotName: 'rankSlot'
  },
  {
    label: '产品信息',
    prop: 'productInfo',
    slotName: 'productInfoSlot'
  },
  {
    label: '分销商',
    prop: 'mainUser',
    slotName: 'mainUser'
  },
  {
    label: '订单数量',
    prop: 'orderCount',
    sortable: 'custom'
  },
  {
    label: '销售产品数量',
    prop: 'productCount',
    sortable: 'custom'
  },
  {
    label: '定制时间',
    prop: 'createTime'
  }
]
export const orderTableCols = [
  {
    label: '日期',
    prop: 'orderDate'
  },
  {
    label: '订单数量',
    prop: 'orderCount'
  },
  {
    label: '销售产品数量',
    prop: 'productCount'
  },
  {
    label: '销售总额(￥)',
    prop: 'productAmount'
  }
]
