<template>
  <div class="statistics app-container">
    <el-tabs v-scrollTop v-model="activeName" type="card" @tab-click="tabsHandleClick">
      <el-tab-pane v-for="{ label, name } in tabsList" :label="label" :name="name" :key="name">
        <template v-if="name === 'user' && checkPermission(['externaladmin:statistics:user'])">
          <MyHeader :sup_this="sup_this" :query="query" :Files="Files" :activeName="activeName"></MyHeader>
          <chartPart :saleData="saleData" v-loading="tableLoading" :query="query" :activeName="activeName"></chartPart>
          <userTablePage
            :sup_this="sup_this"
            :query="query"
            :activeName="activeName"
            :tableData="userTableData"
            v-loading="tableLoading"
            class="table-bottom-margin"
          ></userTablePage>
          <div class="pagination-position">
            <PaginationBar
              :page="page"
              :size="size"
              @refreshTableEventFun="refreshTableEventFun"
              :total="total"
            ></PaginationBar>
          </div>
        </template>

        <template v-else-if="name === 'style' && checkPermission(['externaladmin:statistics:style'])">
          <MyHeader
            :sup_this="sup_this"
            :query="query"
            :Files="Files"
            :activeName="activeName"
            @headerChange="headerChange"
            :categoryOptions="categoryOptions"
            @categoryChange="categoryChange"
          ></MyHeader>
          <el-row>
            <el-col :span="6" style="line-height: 48px">
              <checkAllData
                ref="checkAllData"
                :tableData="data || []"
                :page="page"
                :size="size"
                :total="total"
                :isPageChange="isPageChange"
                :setIsPageChangeFalse="setIsPageChangeFalse"
                :getAllData="getAllData"
                :selectionData="styleSelectData"
                :getElTableInstance="getElTableInstance"
                @checkAllStatus="checkAllStatusChange"
              ></checkAllData>
              <ExportBtn
                :styleSelectData="selectionDataAllArr"
                :getSelectionDataAllArr="getSelectionDataAllArr"
                :query="query"
                v-if="checkPermission(['externaladmin:statistics:style:export'])"
              ></ExportBtn>
            </el-col>
          </el-row>
          <CommonTable
            height="auto"
            :cols="cols"
            :infoData="data"
            @selection-change="selRow"
            v-loading="tableLoading"
            ref="styleTableRef"
            class="table-bottom-margin"
          >
            <template #styleCodeSlot="{ scoped: { row } }">
              <el-popover width="200" trigger="hover" popper-class="style-table-popover-class">
                <template>
                  <el-table :data="row.sizeList || []" border>
                    <el-table-column label="尺码" prop="sizeName"></el-table-column>
                    <el-table-column label="销售数量" prop="productCount"></el-table-column>
                  </el-table>
                </template>
                <div slot="reference">
                  {{ row.styleName || '暂无' }}
                </div>
              </el-popover>
            </template>
          </CommonTable>
          <div class="pagination-position">
            <PaginationBar
              @refreshTableEventFun="refreshTableEventFun"
              :total="total"
              :page="page"
              :size="size"
            ></PaginationBar>
          </div>
        </template>
        <template v-else-if="name === 'SKU' && checkPermission(['externaladmin:statistics:SKU'])">
          <MyHeader
            :sup_this="sup_this"
            :query="query"
            :Files="Files"
            :activeName="activeName"
            @headerChange="headerChange"
            :categoryOptions="categoryOptions"
            @categoryChange="categoryChange"
          ></MyHeader>
          <CommonTable
            height="auto"
            :selection="false"
            :cols="cols"
            :infoData="data"
            v-loading="tableLoading"
            @sort-change="sort_change"
            class="table-bottom-margin"
          >
            <template #rankSlot="{ scoped: { index } }">
              {{ index + 1 }}
            </template>
            <template #productInfoSlot="{ scoped: { row } }">
              <div class="product-box">
                <div class="image-box">
                  <el-image :src="row.customShowImageList && row.customShowImageList[0]"></el-image>
                </div>
                <div class="product-info">
                  <p>产品名称: {{ row.displayName || '暂无' }}</p>
                  <p>款式: {{ row.styleName }}</p>
                  <p>尺码: {{ row.sizeName }}</p>
                </div>
              </div>
            </template>

            <template #mainUser="{ scoped: { mainUser,userStatus } }">
              {{ mainUser }}<span v-if="userStatus === 2" style="color:red">(已注销)</span>
            </template>
          </CommonTable>
          <div class="pagination-position">
            <PaginationBar
              @refreshTableEventFun="refreshTableEventFun"
              :total="total"
              :page="page"
              :size="size"
            ></PaginationBar>
          </div>
        </template>
        <template v-else-if="name === 'order' && checkPermission(['externaladmin:statistics:order'])">
          <MyHeader
            :sup_this="sup_this"
            :query="query"
            :Files="Files"
            :howDate="howDate"
            :activeName="activeName"
          ></MyHeader>
          <orderStatistics :data="orderStatisticsData"></orderStatistics>
          <orderPartTabs
            :data="orderTableData"
            :sup_this="sup_this"
            :query="query"
            :cols="cols"
            v-loading="tableLoading"
          ></orderPartTabs>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MyHeader from './module/MyHeader'
import chartPart from './module/chartPart'
import userTablePage from './module/userTablePage'
import ExportBtn from './module/ExportBtn'
import orderStatistics from './module/orderPart/orderStatistics.vue'
import orderPartTabs from './module/orderPart/orderPartTabs.vue'
import { saleFiles, styleFiles, SKUFiles, orderFiles } from './module/MyHeader/formCols'
import { styleTableCols, SKUTableCols, orderTableCols } from './module/tableCols.js'
import { initDataMixin } from '@/mixins'
import { getProductCtegory } from '@/api/statistics.js'
import { getValueFromObj } from '@/utils'
import { initData } from '@/api/data'
import checkAllData from '@/views/components/checkAllData3'

export default {
  mixins: [initDataMixin],
  components: {
    MyHeader,
    chartPart,
    userTablePage,
    checkAllData,
    ExportBtn,
    orderStatistics,
    orderPartTabs
  },
  props: {},
  data() {
    return {
      sup_this: this,
      activeName: 'user',
      isIndeterminate: false, //对el-checkbox控制不完整的全选状态
      tabsList: [
        { label: '分销商', name: 'user' },
        { label: '款式', name: 'style' },
        { label: 'SKU', name: 'SKU' },
        { label: '订单', name: 'order' }
      ],
      url: '/externaladmin/reportService/misDistributorOrderDailyData/list',
      saleData: {},
      userTableData: [],
      categoryOptions: [],
      orderTableData: [],
      orderStatisticsData: {},
      orderItems: [{ column: '', asc: '' }],
      howDate: [
        {
          label: '今日',
          value: '1'
        },
        {
          label: '七日',
          value: '3'
        }
      ],
      special: true,
      isSort: true,
      // 全选必加
      styleSelectData: [],
      selectionDataAllArr: []
    }
  },
  computed: {
    Files() {
      switch (this.activeName) {
        case 'user':
          return saleFiles
        case 'style':
          return styleFiles
        case 'SKU':
          return SKUFiles
        case 'order':
          return orderFiles
        default:
          return []
      }
    },
    cols() {
      switch (this.activeName) {
        case 'user':
          return []
        case 'style':
          return styleTableCols
        case 'SKU':
          return SKUTableCols
        case 'order':
          return orderTableCols
        default:
          return []
      }
    }
  },
  methods: {
    tabsHandleClick() {
      this.query = {}
      switch (this.activeName) {
        case 'user':
          this.url = '/externaladmin/reportService/misDistributorOrderDailyData/list'
          break
        case 'style':
          this.url = '/externaladmin/reportService/misProductStyleDailyData/list'
          break
        case 'SKU':
          this.url = '/externaladmin/reportService/misDistributorProductSkuDailyData/list'
          break
        case 'order':
          this.url = '/externaladmin/reportService/misOrderDailyData/list'
          break
      }
      this.size = 10
      this.page = 1
      this.$nextTick(this.init)
      if (this.activeName === 'style' || this.activeName === 'SKU') {
        this.getCtegory()
      }
    },
    selRow(val) {
      this.styleSelectData = val
    },
    initCallBack(res, postData) {
      if (this.activeName === 'user') {
        const {
          detail: { list, orderCount, productCount, productAmount }
        } = res
        const {
          page: { total: totalUser }
        } = res
        this.saleData = { orderCount, totalUser, productCount, productAmount }
        this.userTableData = list || []
      }
      if (this.activeName === 'order') {
        const {
          detail: { list, orderCount, productAmount, productCount }
        } = res
        // console.log('res', res)
        this.orderStatisticsData = { orderCount, productAmount, productCount }
        this.orderTableData = list || []
      }

      if (this.activeName === 'style') {
        // console.log(res?.detail || [])
        this.data.map((item) => {
          item.id = `${item.categoryName}-${item.styleName}`
        })
        let initCallBack = this.$refs.checkAllData[0]?.initCallBack
        if (initCallBack) initCallBack(res?.detail || [], postData.page.pageIndex)
      }
    },
    headerChange(val) {
      for (let key in val) {
        if (key === 'mainUser') {
          this.$set(this.query, 'createByName', val[key])
        } else {
          this.$set(this.query, key, val[key])
        }
      }
      this.init()
    },
    categoryChange(val) {
      this.$set(this.query, 'categoryId', val)
      this.init()
    },
    async getCtegory() {
      const { detail } = await getProductCtegory({ page: { pageIndex: 1, pageSize: 0 } })
      this.categoryOptions = detail
      // console.log('this.categoryOptions', this.categoryOptions)
    },
    sort_change({ order, prop }) {
      const newProp = this.humpToUnderline(prop)
      this.orderItems[0].column = newProp
      if (order === 'ascending') {
        this.orderItems[0].asc = true
      } else if (order === 'descending') {
        this.orderItems[0].asc = false
      } else {
        this.isSort = false
      }
      if (this.isSort) {
        this.query['orderItems'] = this.orderItems
      } else {
        this.$delete(this.query, 'orderItems')
      }
      this.isSort = true
      this.init()
    },
    humpToUnderline(str) {
      return str.replace(/([A-Z])/g, '_$1').toLowerCase()
    },

    checkAllStatusChange(status) {
      if (['indeterminate', 'all'].includes(status)) {
        this.selectionDataAllArr = [true]
      } else {
        this.selectionDataAllArr = []
      }
      // console.log(this.selectionDataAllArr)
    },
    async getSelectionDataAllArr() {
      let tempArr = await this.$refs.checkAllData[0].getSelectionDataAllArr()
      this.selectionDataAllArr = tempArr
      // console.log('tempArr', tempArr)
      return tempArr
    },
    async getAllData() {
      let res = await awaitResolve(
        initData(this.url, this.method, {
          ...this.params,
          isReturnRelatedData: 0,
          isReturnWaybillChangeRecord: 0,
          page: {
            pageIndex: 1,
            pageSize: 0
          }
        })
      )
      res = res?.detail
      if (!res) this.$message.warning('获取数据失败，请重试')
      return res.map((item) => {
        item.id = `${item.categoryName}-${item.styleName}`
        return item
      })
    },
    getElTableInstance() {
      return this.$refs.styleTableRef[0].table || {}
    }
  }
}
</script>

<style scoped lang="scss">
.statistics {
  ::v-deep {
    .el-tabs__header {
      border-bottom: none;
      .el-tabs__nav {
        border-radius: 0px;
      }
    }
    .el-tabs__item {
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
      width: 100px;
      transition: none;
    }
    .el-tabs__item:hover {
      color: #333;
    }
    .el-tabs__item.is-active {
      border-bottom: 1px solid #e4e7ed;
      background-color: $color-primary;
      color: #fff;
    }
    .product-box {
      display: flex;
      .image-box {
        width: 60px;
        padding-top: 16px;
      }
      .product-info {
        flex: 1;
        p {
          margin: 0;
        }
      }
    }
  }
  .pagination-position {
    position: fixed;
    overflow: hidden;
    bottom: 20px;
    height: 48px;
    left: 70%;
    border-radius: 10px;
    background-color: #fff;
    transform: translateX(-50%);
  }
  .table-bottom-margin {
    margin-bottom: 30px;
  }
}
</style>
<style>
.style-table-popover-class {
  padding: 0;
}
</style>
